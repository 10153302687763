import { useContext, useEffect, useState } from "react";
import { StyledDiv } from "../TableHelpers/TableStyle";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import AttToken from "../../helpers/attToken";
import Tickets, { filtrosType, TicketsType } from "../../service/Tickets";
import { ThemeProvider } from '@mui/material/styles';
import { AxiosError } from "axios";
import SingleNotification from "./SingleNotification";
import { UserContext } from "../../context/UserContext";
import Table from "../Skeletons/Table";
import { Alert, Skeleton, Snackbar } from "@mui/material";
import { SpanChamado } from "./style/AllNotificationsStyle";
import RedirectListModal from "./Modals/RedirectListModal";
import { getMuiTheme } from "../TableHelpers/options";
import TicketsFilters from "./TicketsFilters";
import { toast } from "react-toastify";
import { TicketsContext } from "../../context/TickestsContext";
import ModalTicket from "./Modals/ModalTicket";
import { trackEventMatomo } from "../../helpers/matomo";

const ticketsService = new Tickets();

export default function MyTickets() {
  const { show, setShow } = useContext(UserContext)
  const { emitidos,
    userEmissor,
    expirado,
    modelos,
    modelosNovos,
    inicioRangeDe,
    inicioRangeAte,
    previsaoRangeDe,
    previsaoRangeAte,
    status,
    clientes,
    locais,
    conjuntos,
    unidades,
    ordenarPor,
    ordem,
    responsavel
  } = useContext(TicketsContext)
  const { t } = useTranslation('translation');
  const [open, setOpen] = useState(false);
  const [encaminhados, setEncaminhados] = useState<TicketsType[]>();
  const [openEncaminhados, setOpenEncaminhados] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [itensPerPage, setItensPerPage] = useState(10);
  const [totalItens, setTotalItens] = useState(10);
  const [id, setId] = useState<string | null>(null)

  const { data, isLoading, isFetching, refetch, isRefetching } = useQuery({
    queryKey: ['GET_TICKETS'],
    queryFn: async (pageNmbr) => {
      const token = await AttToken()
      if (token) {
        const mocked = [];
        const filtros: filtrosType = {
          userEmissor: userEmissor?.map((ele) => ele.value) as string[],
          expirado: expirado as boolean,
          modelos: modelos?.map((ele) => ele.value) as number[],
          modelosNovos: modelosNovos?.map((ele) => ele.value) as string[],
          inicioRange: {
            de: inicioRangeDe as number,
            ate: inicioRangeAte as number,
          },
          previsaoRange: {
            de: previsaoRangeDe as number,
            ate: previsaoRangeAte as number
          },
          status: status,
          cliente: clientes?.map((ele) => ele.value) as string[],
          local: locais?.map((ele) => ele.value) as string[],
          conjunto: conjuntos?.map((ele) => ele.value) as string[],
          unidade: unidades?.map((ele) => ele.value) as string[],
          ordenarPor: ordenarPor?.value as string,
          ordem: ordem,
          responsavel: responsavel?.map((ele) => ele.value) as string[],
        }
        const response = await ticketsService.getAll(token, itensPerPage, pageNumber + 1, filtros, emitidos)
        if (response.encaminhados.length > 0) {
          setOpen(true);
          setEncaminhados(response.encaminhados);
        }
        if (pageNumber) {
          for (let i = 0; i < pageNumber * itensPerPage; i += 1) {
            const mockedObj = {
              inicio: 0,
              usuarioNome: 'ABC',
              nome: 'mockedTIcket',
              dataPrevisao: 0,
              status: 'concluido',
              id: String(i)
            }
            mocked.push(mockedObj)
          }
        }
        if (mocked.length) {
          const newArray = [...mocked, ...response.objChamados]
          setTotalItens(response.totalItens)
          return newArray;
        }
        setTotalItens(response.totalItens)
        return response.objChamados
      }
    },
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    onError: () => {
      console.log(AxiosError)
    }
  })

  const tableOptions: MUIDataTableOptions = {
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    viewColumns: false,
    print: false,
    download: false,
    filter: false,
    filterType: 'textField',
    responsive: 'vertical',
    rowsPerPageOptions: [10, 15, totalItens],
  };

  useEffect(() => {
    refetch()
  }, [pageNumber, itensPerPage, refetch])

  const columns = [
    {
      name: "inicio",
      label: t('notifications.ticket.createdAt'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value: number) => (
          new Date(value).toLocaleString()
        ),
      }
    },
    {
      name: 'usuarioNome',
      label: t('notifications.unread.user'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value: string) => (
          value?.startsWith('anonimo') ? `${t('checklists.Modal.anonymous')}` :
            value
        ),
      }
    },
    {
      name: 'nome',
      label: t('notifications.ticket.tickets'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      }
    },
    {
      name: 'responsavel',
      label: t('notifications.ticket.responsable'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      }
    },
    {
      name: 'prioridade',
      label: t('notifications.ticket.priority'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value: string) => {
          switch (value) {
            case 'alta':
              return (
                <span className="tag is-danger">{t('notifications.myTickets.high')}</span>
              )
            case 'media':
              return (
                <span className="tag is-warning">{t('notifications.myTickets.medium')}</span>
              )
            case 'baixa':
              return (
                <span className="tag is-primary">{t('notifications.myTickets.low')}</span>
              )
            default:
              return (
                <span className="tag is-info">NA</span>
              )
          }
        }
      }
    },
    {
      name: 'novo',
      label: t('notifications.myTickets.requestModel'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value: boolean) => {
          return (
            <>
              {value ? 'Chamado 2.0' : 'Chamado Legado'}
            </>)
        }
      }
    },
    {
      name: "dataPrevisao",
      label: t('notifications.ticket.limitDate'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value: number) => (
          value ?
            new Date(value).toLocaleString() : ''
        ),
      }
    },
    {
      name: "status",
      label: t('status'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value: string) => {
          switch (value) {
            case 'emandamento':
              return (
                <span className="tag is-info">{t('notifications.statusProgress')}</span>
              )
            case 'pendente':
              return (
                <span className="tag is-warning">{t('notifications.statusBad')}</span>
              )
            case 'concluido':
              return (
                <span className="tag is-success">{t('notifications.statusOk')}</span>
              )
            case 'recusado':
              return (
                <span className="tag is-danger">{t('notifications.ticket.refused')}</span>
              )
            case 'cancelado':
              return (
                <span className="tag is-danger">{t('notifications.ticket.canceled')}</span>
              )
            default:
              return (
                <></>
              )
          }
        }
      }
    },
    {
      name: "id",
      label: t(`ticketDetail.open`),
      options: {
        display: false,
        filter: false,
        sort: false,
        sortThirdClickReset: false,

      }
    },
    {
      name: "responsavelNome",
      label: t('notifications.ticket.responsable'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value: number) => (
          value ?
            value : 'NA'
        ),
      }
    },
  ];

  const translatedTextLabels: MUIDataTableOptions = {
    page: pageNumber,
    rowsPerPage: itensPerPage,
    count: totalItens,
    onRowClick: (rowData) => {
      trackEventMatomo('meus chamados', 'click', 'row', 'abre chamado')
      setShow(true)
      setId(rowData[8])
    },
    onChangePage: (currentPage: number) => {
      trackEventMatomo('meus chamados', 'click', 'button', 'muda página')
      setPageNumber(currentPage);
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      trackEventMatomo('meus chamados', 'click', 'button', 'muda página')
      setPageNumber(0)
      setItensPerPage(numberOfRows);
    },
    search: false,
    sort: false,
    textLabels: {
      body: {
        noMatch: `${t('table.noMatch')}`,
        toolTip: `${t('table.toolTip')}`,
        columnHeaderTooltip: column =>
          `${t('table.columnHeaderTooltip')} ${column.label}`
      },
      pagination: {
        next: `${t('table.next')}`,
        previous: `${t('table.previous')}`,
        rowsPerPage: `${t('table.rowsPerPage')}`,
        displayRows: `${t('table.displayRows')}`
      },
      toolbar: {
        search: `${t('table.search')}`,
        filterTable: `${t('table.filterTable')}`
      },
      filter: {
        title: `${t('table.title')}`,
        reset: `${t('table.reset')}`
      },
      viewColumns: {
        title: `${t('table.viewColumnsTitle')}`
      },
    },
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }

  return (
    <>
      {openEncaminhados && (
        <RedirectListModal
          open={openEncaminhados}
          setOpen={setOpenEncaminhados}
          chamados={encaminhados}
        />
      )}
      <Snackbar
        open={open}
        autoHideDuration={60000e10}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
          <SpanChamado onClick={() => {
            setOpenEncaminhados(true);
          }}>
            {`${encaminhados?.length} ${encaminhados && encaminhados.length > 1 ? `${t('notifications.mytickets.requests')}` : `${t('notifications.mytickets.request')}`} ${t('notifications.mytickets.forwardedToU')}`}
          </SpanChamado>
        </Alert>
      </Snackbar>
      <div style={{paddingBottom: '30px'}}>
      <StyledDiv>
        {isLoading || isRefetching || isFetching ? (
          <Skeleton height={100} width={'100%'} style={{ marginTop: '-.9em' }} />
        ) : (
          <form style={{ marginBottom: '1em' }}
            onSubmit={(e) => {
              trackEventMatomo('meus chamados', 'click', 'button', 'filtra chamados')
              e.preventDefault();
              setPageNumber(0);
              toast.promise(
                refetch(),
                {
                  pending: t('notifications.myTickets.filtringRequests'),
                  success: t('notifications.myTickets.requestsFiltred'),
                  error: t('notifications.myTickets.errorFiltering')
                }
              )
            }}
          >
            <TicketsFilters
              refetch={refetch}
              isFetching={isFetching}
              isRefetching={isRefetching}
              loadingData={isLoading}

            />
          </form>
        )}

        {isLoading || isFetching || isRefetching ? (
          <>
            <Table />
          </>
        ) :
          <>
            {show &&
              <ModalTicket id={id} setId={setId} />
            }
            {data && <ThemeProvider theme={getMuiTheme()}>
              <MUIDataTable
                title={t('notifications.ticket.tabtitle')}
                data={data as TicketsType[]}
                columns={columns}
                options={{ ...tableOptions, ...translatedTextLabels }}
              />
            </ThemeProvider>}
          </>
        }
      </StyledDiv >
      </div>
    </>
  )
}